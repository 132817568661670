@use "../mixins/mq";
@use "../_config/colors";
@use "../_base/svg";
@use "../_base/typography";

@use "node_modules/@splidejs/splide/dist/css/splide-core.min.css";
@use "node_modules/@splidejs/splide/dist/css/splide.min.css";

.gallery {
  &__title {
    margin-bottom: 2rem;
  }

  &__item {
    max-width: 90vw;
    cursor: grab;
    background-color: #F1F1F190;
  }

  &__arrow {
    background-color: var(--color-primary-1);
    height: 5rem;
    width: 5rem;
    top: auto;
    bottom: 2rem;
    transform: none;

    @include mq.mq($from: 800px) {
      bottom: auto;
      top: 50%;
      height: 6rem;
      width: 6rem;
      transform: translateY(-50%);
    }

    svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: var(--color-white);

      @include mq.mq($from: 800px) {
        width: 2.6rem;
        height: 2.6rem;
      }
    }
  }

  &__pagination {
    margin-top: 1rem;
    font-size: 2rem;
    text-align: center;

    @include mq.mq($from: 800px) {
      margin-top: 2rem;
      font-size: 3rem;
    }

    &-total {
      font-weight: 700;
    }
  }
}


/*

:root {
	--slideWidthDesktop: 113.2rem;
	--slideWidthWide: 142.6rem;
};

.c-gallery{
	color: var(--color-primary-1);
}

.c-gallery__title {
	margin: 0 auto var(--spacing-40);

	@include mq.mq($from: large) {
		max-width: var(--slideWidthDesktop);
	}

	@include mq.mq($from: wide) {
		max-width: var(--slideWidthWide);
	}
}

.c-gallery__background {
	padding-top: var(--spacingWide);
	background-color: var(--lemon);
	transition: min-height 0.3s ease;

	@include mq.mq($from: medium) {
		padding-top: 0;
	}

}

.c-gallery__wrapper {
	position: relative;
	//height: 500px;

	.c-gallery__button {
		width: 33px;
		padding: 0;
		color: var(--blue);
		transition: transform var(--animationDuration) var(--animationEase);

		.not-touch & {
			&:hover {
				transform: scale(1.3);
			}
		}
	}

	.c-gallery__button-prev {
		display: none;
		visibility: hidden;
		left: 50%;
		margin-left: calc(0px - ((#{var(--slideWidthDesktop)} + 3.3rem) / 2));
		@include background-svg.params(svg.$svg-arrow-left, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);

		&:after {
			display: none;
			visibility: hidden;
		}

		@include mq.mq($from: medium) {
			display: flex;
			visibility: visible;
		}

		@include mq.mq($from: wide) {
			margin-left: calc(0px - ((#{var(--slideWidthWide)} + 3.3rem) / 2));
		}
	}

	.c-gallery__button-next {
		left: 322px;
		//left: 300px;
		right: auto;
		margin-left: calc(3.3rem / -2);
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-1);


		&:after {
			display: none;
			visibility: hidden;
		}

		@include mq.mq($from: medium) {
			left: auto;
			right: 50%;
			margin-left: 0;
			margin-right: calc(0px - ((#{var(--slideWidthDesktop)} + 3.3rem) / 2));
		}

		@include mq.mq($from: wide) {
			margin-right: calc(0px - ((#{var(--slideWidthWide)} + 3.3rem) / 2));
		}

	}
}


.c-gallery__slider .c-gallery__slide {
	width: 322px;
	max-width: 322px;

	@include mq.mq($from: medium) {
		width: 100%;
		max-width: 113.2rem;
	}

	@include mq.mq($from: wide) {
		max-width: 142.6rem;
	}
}

.c-gallery__bottom {
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	padding: var(--spacingMedium) 0 var(--spacingBig);

	@include mq.mq($from: medium) {
		max-width: 113.2rem;
		padding-bottom: var(--spacingWide);;
	}

	@include mq.mq($from: wide) {
		max-width: 142.6rem;
	}
}

.c-gallery__test-wrapper {
	display: flex;
}

.c-gallery__pagination-fraction {
	display: flex;
	bottom: 35px;
	margin-top: 7.5rem;

	padding-left: 3.6rem;
	width: 15rem;
	//height: 5.8rem;



	&:after {
		display: none;
		visibility: hidden;

	}

	@include mq.mq($from: medium) {
		visibility: visible;
		align-items: center;
		align-self: flex-start;
		bottom: 0px;

	}

	@include mq.mq($from: large) {
		left: 50%;
		margin-left:  calc(0px - ((#{var(--slideWidthDesktop)} - 2rem) / 2));
		justify-content: flex-start;

	}

	@include mq.mq($from: wide) {
		margin-left: calc(0px - ((#{var(--slideWidthWide)} + 0rem) / 2));
	}
}


.c-gallery__pagination-imageCaption{

	z-index: 1;
	order: 2;
	position: relative;
	margin-left: 3.6rem;
	padding-top: 6.7rem;
	@extend .font-size-300;


	@include mq.mq($from: medium) {
		height: 5.8rem;
		padding-left: 13rem;
		padding-top: 0.8rem;
	}

	@include mq.mq($from: large) {
		height: 5.8rem;
		padding-left: 13rem;
		padding-top: 0.8rem;
	}

}



.swiper-pagination-current, .swiper-pagination-current-prefix{
	font-size: 4.2rem;
	line-height: 5.8rem;
}

.c-gallery__pagination-current {

	@include mq.mq($from: large) {
		font-size: 4.2rem;
		line-height: 5.8rem;
	}
}

.swiper-pagination-total, .swiper-pagination-total-separator{
	font-size: 2.5rem;
	line-height: 3.8rem;
	align-self: flex-start;
}



.c-gallery__slide-wrapper {
	position: relative;
	height: 0;
	padding-bottom: 100%;
	overflow: visible;

	@include mq.mq($from: medium) {
		padding-bottom: 77.56%;
	}

	.figure {
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		transform: translateY(-50%);
	}
}

*/
